<template>
  <div class="border rounded mb-2 px-3 section-container">
    <div class="text-muted border-bottom h6 mt-3 pb-1">
      <div v-if="!edit" class="d-flex align-items-center">
        <i class="fa fa-align-justify handle-section"></i>
        <b-button variant="light" size="sm" class="py-0 ml-2" @click="toggleMode()">
          <i class="fas fa-edit" />
        </b-button>
        <span class="ml-2">{{section.name}}</span>
        <span class="mx-2">-</span>
        <span>{{authStateLabel}}</span>
        <b-button variant="light" size="sm" class="py-0 ml-auto text-danger" @click="deleteSection(section.id)">
          <i class="fas fa-trash-alt" /> supprimer
        </b-button>
      </div>
      <b-form v-else inline @submit="onRenameSection" >
        <b-button variant="light" size="sm" class="py-0 text-danger" @click="toggleMode()">
          <i class="fas fa-times" />
        </b-button>
        <b-form-input name="sectionName" size="sm" v-model="section.name" class="ml-2" />
        <b-form-select
          v-model="section.auth_state"
          name="authState"
          size="sm"
          value-field="id"
          text-field="text"
          :options="authStateOptions"
          class="ml-1"
        />
        <b-button variant="success" size="sm" type="submit" class="ml-2 py-0">
          <i class="fas fa-check" /> valider
        </b-button>
      </b-form>
    </div>
    <vue-draggable
      :list="section.categories"
      group="items"
      class="category-container"
      ghost-class="drag-ghost"
      handle=".handle-category"
      :disabled="saving"
    >
      <article
        :key="`type:${category.type}cat:${category.id}code:${category.code}`"
        v-for="category in section.categories"
      >
      <vue-section-category
        class="d-flex align-items-center py-2"
        :category="category"
        :site-id="siteId"
        draggable
        :remove-item="removeItem"
        :section-id="section.id"
        :toggle-category="_toggleCategory"
      ></vue-section-category>
      </article>
    </vue-draggable>
  </div>
</template>

<script>
import VueDraggable from 'vuedraggable'

export default {
  name: 'Section',
  components: {
    VueDraggable,
    VueSectionCategory: () => import(/* webpackChunkName: "sections-group" */ './Category.vue')
  },
  props: {
    section: Object,
    siteId: Number,
    addItem: Function,
    removeItem: Function,
    deleteSection: Function,
    renameSection: Function,
    toggleCategory: Function
  },
  data () {
    return {
      saving: false,
      edit: false,
      authStateOptions: [
        { id: 1, text: 'Visible par tous' },
        { id: 2, text: 'Visible par les utilisateurs authentifiés' },
        { id: 3, text: 'Visible par les utilisateurs anonymes' }
      ]
    }
  },
  computed: {
    authStateLabel () {
      if (this.section.auth_state) {
        const option = this.authStateOptions.find(o => o.id === this.section.auth_state)
        return option.text
      }
      return null
    }

  },
  methods: {
    toggleMode () {
      this.edit = !this.edit
    },
    onRenameSection (evt) {
      evt.preventDefault()
      this.toggleMode()
    },
    _toggleCategory (isActive, categoryId) {
      this.toggleCategory(isActive, categoryId, this.section.id)
    }
  }
}
</script>

<style scoped>
.handle-section { cursor: pointer; }
input[name="sectionName"] { width: 240px; }
select[name="authState"] { width: 192px; }
</style>
