<template>
    <div class="py-3">
      <b-form @submit.prevent="onSubmit" @change="onChange">
        <div class="mb-3">
          <label>Nom du groupe</label>
          <div>
            <b-form-input
              size="sm"
              v-model="group.name"
              type="text"
              required
            />
            <small class="help-block form-text text-muted">Nom d'identification de la section</small>
          </div>
        </div>
        <div class="mb-3">
          <label>Les clients</label>
          <div>
            <b-form-checkbox-group
              v-model="group.clients"
              :options="clientsOptions"
              switches
            />
          </div>
        </div>
        <div class="mb-3">
          <label>Le site</label>
          <div>
            <div v-if="mode === 'edition'" class="legends">
              <span :data-legend="`s.${group.site}`"></span>
              <span class="smallx text-muted pl-1">{{ sitesGetSite(group.site).name }}</span>
            </div>
            <div v-else>
              <vue-sites-select target="site" :selected="group.site" @siteChange="siteChange" />
              <b-form-invalid-feedback id="site-feedback" v-if="$v.group.site.$error">Sélectionner</b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label>La catégorie parente</label>
          <div v-if="mode === 'edition'">
            <div v-if="group_category">
              <strong class="ml-1">{{group_category.name}}</strong>
              <small class="text-muted ml-1">#{{group_category.id}}</small>
            </div>
            <div else>-</div>
          </div>
          <div v-else>
            <vue-categories-tree-select
              :selected="group.parent_category"
              context="edit-section"
              target="parent_category"
              :site="group.site"
              @categoryChange="categoryChange"
            />
            <small class="help-block form-text text-muted">La catégorie parente de la section, par défaut la racine du site</small>
          </div>
        </div>
        <div class="mb-3">
          <label>Centres</label>
          <div>
            <multiselect v-model="group.centers" :showPointer="false" :options="get_options" @input="centerConfigChange" :multiple="true" placeholder="Sélectionnez des centres"  :close-on-select="false" :clear-on-select="false" label="center_name" track-by="center_name">
              <template slot="option" slot-scope="props">
                  <span v-if="props.option.center_id">{{ props.option.center_name }}</span>
                  <span v-else class="badge badge-secondary">{{ props.option.center_name }}</span>
              </template>
            </multiselect>
            <small class="help-block form-text text-muted">Si aucune configuration alors le groupe sera renvoyé</small>
          </div>
        </div>
        <div class="mb-3">
          <label>Cantines</label>
          <vue-canteen-multiselect :canteens-ids="group.canteens_ids" @canteensChange="updateCanteens"/>
        </div>
        <div>
          <label />
          <div>
            <b-form-checkbox
              size="sm"
              class="mb-3"
              switch
              name="active-switch"
              v-model="group.is_active"
            >Active</b-form-checkbox>
          </div>
        </div>
        <div>
          <label />
          <div class="border-top pt-2 d-flex">
            <b-button type="submit" size="sm" variant="primary" :disabled="!mChanged" class="px-3">
              <span v-if="mode === 'creation'">Créer le groupe</span>
              <span v-else-if="mode === 'edition'">Modifier</span>
            </b-button>
            <b-button v-if="mode === 'edition'"
              class="ml-auto py-0" variant="danger" size="sm"
              @click="deleteSectionGroup"
            ><i class="fas fa-trash-alt mr-1" /> Supprimer</b-button>
          </div>
        </div>
      </b-form>
    </div>
</template>

<script>
import store from '../../store'
import eventEmitter from '../../event-emitter'
import ApiDeleevSectionGroups from '../../api/deleev/section_groups'
import VueCategoriesTreeSelect from '../../components/helpers/categoriesTreeSelect'
import VueSitesSelect from '../../components/helpers/sitesSelect'
import VueCanteenMultiselect from '../../components/helpers/canteenMultiSelect.vue'
import mSections from '../../mixins/sections'
import ApiSearch from '../../api/search/canteens'
import { required } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'

export default {
  name: 'Group',
  components: {
    VueCategoriesTreeSelect,
    VueSitesSelect,
    VueCanteenMultiselect,
    Multiselect
  },
  props: {
    group: Object,
    mode: String
  },
  computed: {
    group_category () {
      if (!this.group.id || !this.group.parent_category) return null
      return store.getCategory(this.group.parent_category, this.group.site)
    },
    get_options () {
      let centers = store.getCenters()
      let res = centers.map(c => {
        return { center_id: c.id, center_name: c.name }
      })
      res.unshift({ center_type: 1, center_name: 'Complet' })
      res.unshift({ center_type: 2, center_name: 'Partiel' })
      res.unshift({ center_type: 3, center_name: 'Cafet' })
      return res
    }
  },
  data () {
    return {
      mChanged: false,
      clientsOptions: mSections.getDevices(),
      searchResults: [],
      canteenNamesById: {},
      searchTimer: null
    }
  },
  validations: {
    group: {
      name: { required },
      site: { required },
      clients: { required },
      centers: {},
      canteens_ids: {}
    }
  },
  methods: {
    centerConfigChange (data) {
      this.group.centers = data.length ? data : null
    },
    siteChange (data) {
      this.group.site = data.value
      this.group.parent_category = null
    },
    categoryChange (data) {
      this.group.parent_category = data.value
    },
    deleteSectionGroup () {
      this.$emit('deleteSectionGroup')
    },
    onChange () {
      this.mChanged = true
    },
    onSubmit () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      if (this.mode === 'creation') {
        this.createSectionGroup(this.group)
      } else if (this.mode === 'edition') {
        this.updateSectionGroup(this.group.id, this.group)
      }
    },
    updateCanteens ({ canteensIds }) {
      this.group.canteens_ids = [...canteensIds]
      this.onChange()
    },
    async _searchCanteen (q) {
      const results = await ApiSearch.searchCanteens({ query: q })
      this.searchResults = results.hits.map(c => ({ id: Number(c.id), name: c.name }))
    },
    async onSearchCanteen (evt) {
      this.searchTimer && clearTimeout(this.searchTimer)
      if (!evt.target.value) {
        this.searchResults = []
        return
      }

      this.searchTimer = setTimeout(() => this._searchCanteen(evt.target.value), 300)
    },
    clearSearchOnOutsideClick (e) {
      if (this.searchResults.length && !this.$refs.searchBox.contains(e.target) && !e.target.classList.contains('search-btn')) {
        this.searchResults = []
      }
    },
    getCanteenName (canteenId) {
      return this.canteenNamesById[canteenId]
    },
    addCanteen (canteen) {
      this.canteenNamesById[canteen.id] = canteen.name
      if (!this.group.canteens_ids) {
        this.group.canteens_ids = [canteen.id]
      } else if (!this.group.canteens_ids.includes(canteen.id)) {
        this.group.canteens_ids = [...this.group.canteens_ids, canteen.id]
      }
    },
    removeCanteen (canteenId) {
      this.group.canteens_ids = this.group.canteens_ids.filter(cid => cid !== canteenId)
    },
    async createSectionGroup (data) {
      const response = await ApiDeleevSectionGroups.createSectionGroup(data)
      if (response && response.id) {
        eventEmitter.emit('app:notify', { message: `Nouveau groupe de sections enregistré`, type: 'success' })
        await this.$router.push({ name: 'section-group', params: { id: response.id } })
      } else if (response && response.error) {
        eventEmitter.emit('app:notify', { message: response.message, type: 'error' })
      }
    },
    async updateSectionGroup (id, data) {
      const response = await ApiDeleevSectionGroups.updateSectionGroup(id, data)
      if (response && response.id) {
        eventEmitter.emit('app:notify', { message: `Modifications enregistrées`, type: 'success' })
      } else if (response && response.error) {
        eventEmitter.emit('app:notify', { message: response.message, type: 'error' })
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
  form > div { display: flex; align-items: flex-start; }
  form label { font-size: 90%; margin: 0; padding: 4px 10px 0 0; min-width: 136px; }
  form label + div { width: calc(100% - 136px); }
</style>
