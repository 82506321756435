<template>
  <div>
    <div class="d-flex">
      <span v-if="saving">Enregisrement en cours...</span>
      <div class="ml-auto mb-2">
        <b-button
          size="sm"
          variant="primary"
          :disabled="JSON.stringify(initialSections) === JSON.stringify(sections)"
          @click="saveSections"
          class="py-0"
        >
          Enregistrer la configuration
        </b-button>
        <b-button
          size="sm"
          variant="light"
          :disabled="JSON.stringify(initialSections) === JSON.stringify(sections)"
          @click="cancelChanges"
          class="py-0 ml-2"
        >
          Annuler les changements
        </b-button>
      </div>
    </div>
    <div class="d-flex">
      <div id="leftpart" :class="{ 'saving': saving }">
        <vue-draggable
          :list="sections"
          tag="div"
          class="section-container"
          ghost-class="drag-ghost"
          handle=".handle-section"
          :disabled="saving"
        >
          <vue-section
            :key="section.id"
            v-for="section in sections"
            :section="section"
            :add-item="addItem"
            :remove-item="removeItem"
            :deleteSection="deleteSection"
            :renameSection="renameSection"
            :site-id="siteId"
            :toggle-category="toggleCategory"
          >
          </vue-section>
          <div class="pb-2">
            <b-button variant="secondary" class="w-100 py-1"
              @click="addNewSection"
            >
              Ajouter une section
            </b-button>
          </div>
        </vue-draggable>
      </div>
      <div id="rightpart" class="border rounded ml-2 py-2" :class="{ 'saving': saving }">
        <article
          :key="`type:${category.type}cat:${category.id}code:${category.code}`"
          v-for="category in categories_exposed"
          class="d-flex align-items-center pr-2 pl-1 py-1" :class="{ 'ml-4': category.level === 1 }"
        >
          <vue-section-category
            :category="category"
            :add-item="addItem"
            :site-id="siteId"
          />
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import VueDraggable from 'vuedraggable'

export default {
  name: 'Navigation',
  components: {
    VueDraggable,
    VueSection: () => import(/* webpackChunkName: "sections-group" */ './Section.vue'),
    VueSectionCategory: () => import(/* webpackChunkName: "sections-group" */ './Category.vue')
  },
  props: {
    initialSections: Array,
    sections: Array,
    groupId: Number,
    siteId: Number,
    categories: Array,
    sectionCategories: Array,
    addItem: Function,
    removeItem: Function,
    addNewSection: Function,
    deleteSection: Function,
    renameSection: Function,
    saveSections: Function,
    cancelChanges: Function,
    toggleCategory: Function,
    saving: Boolean
  },
  computed: {
    categories_exposed () {
      let _sectionsCategories = []
      this.sections.forEach(o => {
        o.categories.forEach(c => {
          if (c.type === 'cat') _sectionsCategories.push(c.id)
        })
      })

      let _list = []
      this.categories.forEach(o => {
        _list.push(o)
        /*
        // pb avec les categories cantine/cafet :( re-faire proproment...
        if (this.groupId === 1029 && !o.formula_type && o.descendent && o.descendent.length) { // only group-sections for kiosk
          o.descendent.forEach(d => {
            _sectionsCategories.indexOf(d.id) === -1 && _list.push(d)
          })
        }
        */
      })
      return _list
    }
  }
}
</script>

<style scoped>
  .saving {
    opacity: 0.4;
    cursor: wait;
  }
  #leftpart, #rightpart { max-height: calc(100vh - 9rem); overflow-y: auto; }
  #leftpart::-webkit-scrollbar, #rightpart::-webkit-scrollbar {
    width: 1px;
    background-color: #f6f6f6;
    scrollbar-width: thin;
  }
  #leftpart { width: calc(100% - 420px); }
  #rightpart { width: 420px; margin-left: 16px; }
</style>
