<template>
  <div class="category-container">
    <div class="d-flex align-items-center">
      <b-button
        v-if="addItem"
        size="sm"
        variant="light"
        @click="addItem(formattedCategory)"
        class="px-1 py-0"
      >
        <i class="fas fa-chevron-left" />
      </b-button>
      <i v-if="draggable" class="fa fa-align-justify handle-category"></i>
      <vue-thumb :source="formattedCategory.picture_mobile || formattedCategory.thumb || ''" class="xxs mx-2" />
      <b-link v-if="formattedCategory.id" class="category-name text-reset" :to="{ name: 'category', params: { id: category.id } }">
        {{ formattedCategory.name_web || formattedCategory.name }}
      </b-link>
      <span v-else class="text-dark">{{ formattedCategory.name_web || formattedCategory.name }}</span>
      <small class="ml-2">
        <i v-if="category.is_active" class="text-success fas fa-eye"></i>
        <i v-else-if="category.id && !category.is_active" class="text-danger fas fa-eye-slash"></i>
      </small>
      <span v-if="formattedCategory.id" class="text-muted smaller ml-2">#{{formattedCategory.id}}</span>
    </div>
    <div v-if="sectionId" class="ml-auto d-flex align-items-center">
      <b-form-checkbox
        v-if="category.id"
        v-model="category.is_active"
        name="is_active"
        size="sm"
        switch
        @input="_toggleCategory"
      ></b-form-checkbox>
      <b-button
        v-if="removeItem"
        size="sm"
        variant="light"
        @click="removeItem(formattedCategory, sectionId)"
        class="px-1 py-0"
        :disabled="!formattedCategory"
      >
        <i class="fas fa-chevron-right"></i>
      </b-button>
    </div>
  </div>
</template>

<script>
import VueThumb from '@/components/helpers/thumb.vue'
import store from '@/store'
import mSections from '@/mixins/sections'

export default {
  components: {
    VueThumb
  },
  props: {
    category: [Object, Number],
    sectionId: Number,
    draggable: Boolean,
    addItem: Function,
    removeItem: Function,
    toggleCategory: {
      type: Function,
      required: false
    },
    siteId: Number
  },
  data () {
    let formattedCategory = {}
    if (this.category.type === 'cat') {
      formattedCategory = store.getCategory(this.category.id, this.siteId)
    } else if (this.category.type === 'link') {
      formattedCategory = mSections.getLinkCategory(this.category.code)
    } else {
      formattedCategory = this.category
    }
    return { formattedCategory }
  },
  methods: {
    _toggleCategory (isActive) {
      this.toggleCategory(isActive, this.category.id)
    }
  }
}
</script>

<style scoped>
.category-name { font-weight: 500 !important; font-size: .96em; color: #000; }
.handle-category:hover { cursor: pointer; }
</style>
