<template>
  <div>
    <div v-if="internalCanteensIds.length" class="my-2">
      <b-badge variant="dark" class="mr-2 p-1" v-for="canteen in internalCanteens" :key="canteen.id">
        <span>{{canteen.name}}</span>
        <span class="ml-2 search-btn" style="cursor: pointer;" @click="removeCanteen(canteen.id)">X</span></b-badge>
    </div>
    <span v-else class="form-control-sm">Aucune cantine associée</span>
    <div ref="searchBox">
      <input
        type="text"
        placeholder="Ajouter une cantine..."
        class="form-control form-control-sm"
        style="position: relative;"
        v-model="query"
        @input="onSearchCanteen"
        @focus="onSearchCanteen"
      >
      <div v-if="searchResults.length" id="search-results">
        <div v-for="canteen in searchResults" :key="canteen.id" class="py-1 border search-row">
          <span class="mx-2">{{canteen.name}}</span>
          <button
            v-if="!internalCanteensIds.includes(canteen.id)"
            class="btn btn-sm btn-light mx-4 search-btn"
            @click.prevent="addCanteen(canteen)"
          >
            Ajouter
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store'
import ApiSearch from '../../api/search/canteens'

export default {
  name: 'CanteenSelect',
  props: {
    canteensIds: { type: Array, default: () => [] }
  },
  async mounted () {
    if (this.internalCanteensIds.length > 0) {
      store.setCanteens(...this.internalCanteensIds)
    }
    document.addEventListener('click', this.clearSearchOnOutsideClick)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.clearSearchOnOutsideClick)
  },
  computed: {
    internalCanteens () {
      return this.internalCanteensIds.map(id => {
        if (id === -1) {
          return { id: -1, name: 'Clients B2C' }
        } else {
          return this.getCanteen(id)
        }
      })
    }
  },
  data () {
    return {
      query: '',
      searchResults: [],
      searchTimer: null,
      internalCanteensIds: this.canteensIds || []
    }
  },
  methods: {
    async _searchCanteen (q) {
      const results = await ApiSearch.searchCanteens({ query: q })
      if (results.found > 0) {
        this.searchResults = results.hits.map(c => ({ ...c, id: Number(c.id), site_id: Number(c.site_id) }))
      }
      // Add Fake Canteen -1
      if (q.toLowerCase().includes('cli') || q.toLowerCase().includes('b2c')) {
        this.searchResults.push({ id: -1, name: 'Clients B2C' })
      }
    },
    async onSearchCanteen (evt) {
      this.searchTimer && clearTimeout(this.searchTimer)
      if (!evt.target.value) {
        this.searchResults = []
        return
      }

      this.searchTimer = setTimeout(() => this._searchCanteen(evt.target.value), 300)
    },
    clearSearchOnOutsideClick (e) {
      if (this.searchResults.length && !this.$refs.searchBox.contains(e.target) && !e.target.classList.contains('search-btn')) {
        this.searchResults = []
      }
    },
    getCanteen (canteenId) {
      return store.getCanteen(canteenId)
    },
    emitCanteensChange () {
      this.$emit('canteensChange', { canteensIds: this.internalCanteensIds, canteens: this.internalCanteens })
    },
    addCanteen (canteen) {
      store.setCanteen(canteen)
      if (!this.internalCanteensIds) {
        this.internalCanteensIds = [canteen.id]
      } else if (!this.internalCanteensIds.includes(canteen.id)) {
        this.internalCanteensIds = [...this.internalCanteensIds, canteen.id]
      }
      this.emitCanteensChange()
    },
    removeCanteen (canteenId) {
      this.internalCanteensIds = this.internalCanteensIds.filter(cid => Number(cid) !== Number(canteenId))
      this.emitCanteensChange()
    },
    reset () {
      this.query = ''
      this.searchResults = []
      this.searchTimer = null
      this.internalCanteensIds = []
    }
  }
}
</script>

<style scoped>
  form > div { display: flex; align-items: flex-start; }
  form label { font-size: 90%; margin: 0; padding: 4px 10px 0 0; min-width: 136px; }
  form label + div { width: calc(100% - 136px); }
  #search-results {
    position: absolute;
    z-index: 1;
    width: 500px;
    max-height: 300px;
    overflow-y: scroll;
    background-color: white;
  }
  .search-row {
    display: flex;
    justify-content: space-between;
  }
  .search-row:hover {
    background-color: whitesmoke;
  }
</style>
