<template>
  <b-form-select :name="target" v-model="site" :options="selectOptions" size="sm" value-field="id" text-field="name" :disabled="disabled" @change="siteChange" />
</template>

<script>
import store from '../../store'

export default {
  name: 'SitesSelect',
  props: {
    target: String,
    selected: [Number, String],
    disabled: [Boolean],
    withIndifferent: { type: Boolean, default: () => false },
    exclude_sites: { type: Array, default: () => [] }
  },
  mounted () {
    this.computeSelectOptions()
  },
  data () {
    return {
      site: this.selected,
      selectOptions: []
    }
  },
  watch: {
    selected: function () {
      this.site = this.selected
    }
  },
  methods: {
    computeSelectOptions () {
      let _options = []
      if (this.withIndifferent) _options.push({ id: '', name: 'Indifférent' })

      let _sites = store.getSortedSites()
      if (this.exclude_sites) {
        _sites = _sites.filter(o => this.exclude_sites.indexOf(o.id) === -1)
      }
      this.selectOptions = _options.concat(_sites)
    },
    siteChange () {
      this.$emit('siteChange', { target: this.target, value: this.site })
    }
  }
}
</script>
